import ApiService from "@/core/services/api.service";

export const UPDATE_FILES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.upload("file-manager", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
